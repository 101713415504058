<template>
    <div>
        <div class="container">
            <!--<video playsinline autoplay loop muted class="video-parallax">
                <source src="../assets/Durance2020V3.webm" type="video/webm">
            </video>-->
            <img src="../assets/Title_New.jpeg" class="img-parallax"/>
        </div>
        <div class="headercontainer">
            <div>
                <h1>Agenda</h1>
            </div>
        </div>
        <div class="top-div-decoration">
        </div>
        <div class="container">
            <div class="timeline">
                <div class="timecontainer left">
                    <div class="timecontent">
                        <h2>09.03.2024</h2>
                        <h2>Generalversammlung</h2>
                        <p>Die GV findet im Restaurant Bahnhöfli in Entelbuch statt und beginnt um 17:30 Uhr.</p>
                    </div>
                </div>
                <div class="timecontainer right">
                    <div class="timecontent">
                        <h2>13.03 / 20.03.2024</h2>
                        <h2>Hallenbadtrainings</h2>
                        <p>im HPZ Schüpfheim (ab 20:00)</p>
                    </div>
                </div>
                <div class="timecontainer left">
                    <div class="timecontent">
                        <h2>27.04.2024</h2>
                        <h2>Kanuschnuppertag</h2>
                        <p>Weitere Infos folgen.</p>
                    </div>
                </div>
                <div class="timecontainer right">
                    <div class="timecontent">
                        <h2>03.05 - 05.05.2024</h2>
                        <h2>Kanuausflug Moesa</h2>
                        <p>Anpaddeln auf der Moesa</p>
                    </div>
                </div>
                <div class="timecontainer left">
                    <div class="timecontent">
                        <h2>15.06. - 16.06.2024</h2>
                        <h2>Kanuausflug</h2>
                        <p>Kanuausflug ins Berner Oberland, je nach Wasserstand</p>
                    </div>
                </div>
                <div class="timecontainer right">
                    <div class="timecontent">
                        <h2>20.07 - 27.07.2024</h2>
                        <h2>Kanulager</h2>
                        <p>In Argentiere an der Durance</p>
                    </div>
                </div>
                <div class="timecontainer left">
                    <div class="timecontent">
                        <h2>18.08.2024</h2>
                        <h2>Kanupicknick, bzw. Jubiläumsanlass</h2>
                        <p></p>
                    </div>
                </div>
                <div class="timecontainer right">
                    <div class="timecontent">
                        <h2>07.09 / 08.09.2024</h2>
                        <h2>Ausflug</h2>
                        <p>Ausflug nach Hüningen</p>
                    </div>
                </div>
                <div class="timecontainer left">
                    <div class="timecontent">
                        <h2>16.11.2024</h2>
                        <h2>Bootshausfest</h2>
                        <p>Bootshausfest ab 17:00</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="bottom-div-decoration">
        </div>
    </div>
</template>

<script>
export default {
  name: 'Home',
  props: {
    msg: String
  }
}
</script>

<style scoped>

@supports (-webkit-text-stroke: 1px black) {
  .headercontainer h1 {
    -webkit-text-stroke: 2px #2c3e50;
  }
  .headercontainer h3 {
    -webkit-text-stroke: 2px #2c3e50;
  }
  .headercontainer h6 {
    -webkit-text-stroke: 0.5px #2c3e50;
  }
}

@media screen and (max-width: 600px){
  .container{
    background-color: #fff;
    overflow: hidden;
  }  
}

.headercontainer{
  background-color: transparent;
  display:flex;
  /*background-color: rgba(80, 80, 80, 0.6);*/
}

@media screen and (max-width: 600px){
  .headercontainer{
    background-color: transparent;
    display:flex;
    /*background-color: rgba(80, 80, 80, 0.6);*/
    padding-top:40px;
  }
}

.headercontainer h1{
  color: #f2f2f2;
}

video{
  width: 100%;
  z-index: -100;
}

.video-parallax {
    -webkit-transition-position: fixed;
    position: fixed;
}

.img-parallax {
    -webkit-transition-position: fixed;
    position: fixed;
    z-index: -100;
    height: 100%;
    width: 100%;
    object-fit:cover;
}

.headercontainer > div{
  background-color:transparent;
  flex:1;
  color: white;
  font-size: 25px;
}

.container{
  background-color: #fff;
  display:flex;
}

.container:first-child{
  height: 10vh;
  background-color: rgba(0, 0, 0, 0);
}

.container > div{
  flex:1;
  background-color:transparent;
  color: #2c3e50;
  font-size: 16px;
  margin: 25px 8vw 10px 8vw;
}

.clear{
  background-color: transparent;
  /*background-color: rgba(80, 80, 80, 0.6);*/
}

.container div p{
  font-weight: bold;
}

.top-div-decoration{
  border-bottom: 75px solid white;
  border-right: 100vw solid transparent;
  /*background-color: rgba(80, 80, 80, 0.6);*/
}

.top-div-decoration h1{
  position: absolute;
  left: 100px;
  font-size: 80px;
}

.bottom-div-decoration {
  border-top: 75px solid white;
  border-left: 100vw solid transparent;
  /*background-color: rgba(80, 80, 80, 0.6);*/
}

.bottom-div-decoration-text{
  position: absolute;
  right: 120px;
  font-size: 80px;
  margin-top: 0px;
}

* {
  box-sizing: border-box;
}

/* The actual timeline (the vertical ruler) */
.timeline {
  position: relative;
  margin: 0 auto;
}

/* The actual timeline (the vertical ruler) */
.timeline::after {
  content: '';
  position: absolute;
  width: 6px;
  background-color: #2c3e50;
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -3px;
}

/* Container around content */
.timecontainer {
  padding: 10px 40px;
  position: relative;
  background-color: inherit;
  width: 50%;
}

/* The circles on the timeline */
.timecontainer::after {
  content: '';
  position: absolute;
  width: 25px;
  height: 25px;
  right: -17px;
  background-color: white;
  border: 4px solid #2c3e50;
  top: 15px;
  border-radius: 50%;
  z-index: 1;
}

/* Place the container to the left */
.left {
  left: 0;
}

/* Place the container to the right */
.right {
  left: 50%;
}

/* Add arrows to the left container (pointing right) */
.left::before {
  content: " ";
  height: 0;
  position: absolute;
  top: 22px;
  width: 0;
  z-index: 1;
  right: 30px;
  border: medium solid #2c3e50;
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent #2c3e50;
}

/* Add arrows to the right container (pointing left) */
.right::before {
  content: " ";
  height: 0;
  position: absolute;
  top: 22px;
  width: 0;
  z-index: 1;
  left: 30px;
  border: medium solid #2c3e50;
  border-width: 10px 10px 10px 0;
  border-color: transparent #2c3e50 transparent transparent;
}

/* Fix the circle for containers on the right side */
.right::after {
  left: -16px;
}

/* The actual content */
.timecontent {
  padding: 20px 30px;
  background-color: #2c3e50;
  position: relative;
  color: white;
  border-radius: 6px;
}

@media screen and (max-width: 600px) {
  h2{
    font-size: 18px;
  }
  p{
    font-size: 14px;
  }
}

/* Media queries - Responsive timeline on screens less than 600px wide */
@media screen and (max-width: 600px) {
/* Place the timelime to the left */
  .timeline::after {
    left: 31px;
  }

/* Full-width containers */
  .timecontainer {
    width: 100%;
    padding-left: 70px;
    padding-right: 25px;
  }

/* Make sure that all arrows are pointing leftwards */
  .timecontainer::before {
    left: 60px;
    border: medium solid #2c3e50;
    border-width: 10px 10px 10px 0;
    border-color: transparent #2c3e50 transparent transparent;
  }

/* Make sure all circles are at the same spot */
  .left::after, .right::after {
    left: 15px;
  }

/* Make all right containers behave like the left ones */
  .right {
    left: 0%;
  }
}

</style>
